import type { PropsWithChildren } from 'react';

import { Icon } from './icon';

export function InfoBannerText({ children }: PropsWithChildren) {
  return (
    <p className="m-0 text-left text-[12px] font-normal leading-[18px] tracking-[-0.25px] text-primary">{children}</p>
  );
}

export function InfoBanner({ children }: PropsWithChildren) {
  return (
    <div className="flex items-stretch rounded-sm bg-[#E7E0F3]">
      <div className="w-[6px] rounded-l-md bg-[#4000bf4d]" />
      <div className="px-2 pt-[8px]">
        <Icon icon="circle-info" className="h-[16px] w-[16px] text-primary" />
      </div>
      <div className="flex flex-grow flex-col gap-2 space-y-2 pb-2 pr-2 pt-2">{children}</div>
    </div>
  );
}
